export const SUBSCRIPTION_PLAN_ELITE = 'elite'
export const SUBSCRIPTION_PLAN_ONBOARDING = 'onboarding'
export const SUBSCRIPTION_PLAN_FREE = 'free'
export const SUBSCRIPTION_PLAN_ADVANCED = 'advanced'
export const SUBSCRIPTION_PLAN_PREMIUM = 'premium'
export const SUBSCRIPTION_PLAN_DOMAIN = 'domain'

export const SUBSCRIPTION_ADD_ON_TURBO_SALES_BASIC = 'turbo_sales_basic'
export const SUBSCRIPTION_ADD_ON_TURBO_SALES_HIGH = 'turbo_sales_high'
export const SUBSCRIPTION_ADD_ON_TURBO_SALES_MASSIVE = 'turbo_sales_massive'

export const SUBSCRIPTION_PLAN_FISCAL_NOTES = 'fiscal_notes'
export const SUBSCRIPTION_PLAN_FISCAL_NOTES_100 = 'fiscal_notes_100'
export const SUBSCRIPTION_PLAN_FISCAL_NOTES_300 = 'fiscal_notes_300'
export const SUBSCRIPTION_PLAN_FISCAL_NOTES_1000 = 'fiscal_notes_1000'

export const PLAN_PRIORITIES = {
  [SUBSCRIPTION_PLAN_ELITE]: 5,
  [SUBSCRIPTION_PLAN_PREMIUM]: 4,
  [SUBSCRIPTION_PLAN_ADVANCED]: 3,
  [SUBSCRIPTION_PLAN_ONBOARDING]: 2,
  [SUBSCRIPTION_PLAN_FREE]: 1
}

export const SUBSCRIPTION_FISCAL_NOTES_PLANS = [
  SUBSCRIPTION_PLAN_FISCAL_NOTES,
  SUBSCRIPTION_PLAN_FISCAL_NOTES_100,
  SUBSCRIPTION_PLAN_FISCAL_NOTES_300,
  SUBSCRIPTION_PLAN_FISCAL_NOTES_1000
]
