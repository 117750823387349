export const COUNTRY_DIAL_CODE_BRAZIL = '55'

export const COUNTRY_CODE_ARGENTINA = 'AR'
export const COUNTRY_CODE_AUSTRALIA = 'AU'
export const COUNTRY_CODE_AUSTRIA = 'AT'
export const COUNTRY_CODE_BELGIUM = 'BE'
export const COUNTRY_CODE_BRAZIL = 'BR'
export const COUNTRY_CODE_BULGARIA = 'BG'
export const COUNTRY_CODE_CANADA = 'CA'
export const COUNTRY_CODE_CROATIA = 'HR'
export const COUNTRY_CODE_CYPRUS = 'CY'
export const COUNTRY_CODE_DENMARK = 'DK'
export const COUNTRY_CODE_ESTONIA = 'EE'
export const COUNTRY_CODE_FINLAND = 'FI'
export const COUNTRY_CODE_FRANCE = 'FR'
export const COUNTRY_CODE_GERMANY = 'DE'
export const COUNTRY_CODE_GREECE = 'GR'
export const COUNTRY_CODE_HONG_KONG = 'HK'
export const COUNTRY_CODE_HUNGARY = 'HU'
export const COUNTRY_CODE_IRELAND = 'IE'
export const COUNTRY_CODE_ITALY = 'IT'
export const COUNTRY_CODE_JAPAN = 'JP'
export const COUNTRY_CODE_LATVIA = 'LV'
export const COUNTRY_CODE_LITHUANIA = 'LT'
export const COUNTRY_CODE_LUXEMBOURG = 'LU'
export const COUNTRY_CODE_MALAYSIA = 'MY'
export const COUNTRY_CODE_MALTA = 'MT'
export const COUNTRY_CODE_MEXICO = 'MX'
export const COUNTRY_CODE_NETHERLANDS = 'NL'
export const COUNTRY_CODE_NORWAY = 'NO'
export const COUNTRY_CODE_POLAND = 'PL'
export const COUNTRY_CODE_PORTUGAL = 'PT'
export const COUNTRY_CODE_ROMANIA = 'RO'
export const COUNTRY_CODE_SINGAPORE = 'SG'
export const COUNTRY_CODE_SLOVAKIA = 'SK'
export const COUNTRY_CODE_SLOVENIA = 'SI'
export const COUNTRY_CODE_SPAIN = 'ES'
export const COUNTRY_CODE_SWEDEN = 'SE'
export const COUNTRY_CODE_SWITZERLAND = 'CH'
export const COUNTRY_CODE_UNITED_ARAB_EMIRATES = 'AE'
export const COUNTRY_CODE_UNITED_KINGDOM = 'GB'
export const COUNTRY_CODE_UNITED_STATES = 'US'
export const COUNTRY_CODE_NEW_ZEALAND = 'NZ'
export const COUNTRY_CODE_PANAMA = 'PA'
export const COUNTRY_CODE_CHILE = 'CL'
export const COUNTRY_CODE_COLOMBIA = 'CO'
export const COUNTRY_CODE_PERU = 'PE'
export const COUNTRY_CODE_BOLIVIA = 'BO'
export const COUNTRY_CODE_COSTA_RICA = 'CR'
export const COUNTRY_CODE_DOMINICAN_REPUBLIC = 'DO'
export const COUNTRY_CODE_ECUADOR = 'EC'
export const COUNTRY_CODE_EL_SALVADOR = 'SV'
export const COUNTRY_CODE_GUATEMALA = 'GT'
export const COUNTRY_CODE_HONDURAS = 'HN'
export const COUNTRY_CODE_NICARAGUA = 'NI'
export const COUNTRY_CODE_PARAGUAY = 'PY'
export const COUNTRY_CODE_URUGUAY = 'UY'
export const COUNTRY_CODE_ALBANIA = 'AL'
export const COUNTRY_CODE_ANDORRA = 'AD'
export const COUNTRY_CODE_BELARUS = 'BY'
export const COUNTRY_CODE_BOSNIA_HERZEGOVINA = 'BA'
export const COUNTRY_CODE_CZECH_REPUBLIC = 'CZ'
export const COUNTRY_CODE_ICELAND = 'IS'
export const COUNTRY_CODE_LIECHTENSTEIN = 'LI'
export const COUNTRY_CODE_MOLDOVA = 'MD'
export const COUNTRY_CODE_MONACO = 'MC'
export const COUNTRY_CODE_MONTENEGRO = 'ME'
export const COUNTRY_CODE_NORTH_MACEDONIA = 'MK'
export const COUNTRY_CODE_RUSSIA = 'RU'
export const COUNTRY_CODE_SAN_MARINO = 'SM'
export const COUNTRY_CODE_SERBIA = 'RS'
export const COUNTRY_CODE_UKRAINE = 'UA'
export const COUNTRY_CODE_BELIZE = 'BZ'
export const COUNTRY_CODE_GUYANA = 'GY'
export const COUNTRY_CODE_SURINAME = 'SR'
export const COUNTRY_CODE_VENEZUELA = 'VE'
